$navbar-background-color: transparent;
$navbar-height: 5.5rem;

$navbar-item-color: $black;
$navbar-item-hover-color: $yellow;
$navbar-item-hover-background-color: transparent;

$navbar-item-active-color: $yellow;
$navbar-item-active-background-color: transparent;
$navbar-item-img-max-height: 2rem;

$navbar-burger-color: $black;

$navbar-tab-hover-background-color: transparent;
$navbar-tab-hover-border-bottom-color: transparent;

$navbar-tab-active-color: $yellow;
$navbar-tab-active-background-color: transparent;
$navbar-tab-active-border-bottom-color: transparent;
$navbar-tab-active-border-bottom-style: none;
$navbar-tab-active-border-bottom-width: 0;
$navbar-dropdown-background-color: $white;
$navbar-dropdown-border-top: $white-ter;
$navbar-dropdown-arrow: $grey;

$navbar-dropdown-item-hover-color: $yellow;
$navbar-dropdown-item-hover-background-color: $white;
$navbar-dropdown-item-active-color: $yellow;
$navbar-dropdown-item-active-background-color: $white;

$navbar-divider-background-color: $white;
$navbar-divider-height: 2px;
$navbar-bottom-box-shadow-size: 4px;
