@import "_backgrounds.sass";

body {
    min-width: 555px; }

.container.flash {
    margin-top: 5rem; }

h1, h2, h3, h4, h5, h6, .button {
    font-family: $family-secondary;
    text-transform: uppercase;
    font-weight: bold; }
h1 {
    @media (max-width: 1023px) {
        font-size: 3rem;
        line-height: 4rem;
        margin-bottom: 3rem; }
    @media (min-width: 1024px) and (max-width: 1407px) {
        font-size: 5rem;
        line-height: 6rem;
        margin-bottom: 5rem; }
    @media (min-width: 1408px) {
        font-size: 6rem;
        line-height: 7rem;
        margin-bottom: 6rem; } }
.header-shrink {
    @media (min-width: 1408px) {
        max-width: 50%; } }
.header-robot-shrink {
    @media (min-width: 1408px) {
        max-width: 75%; } }
h2 {
    @media (max-width: 1023px) {
        font-size: 2rem;
        line-height: 3rem;
        margin: 2rem 0; }
    @media (min-width: 1024px) and (max-width: 1407px) {
        font-size: 3rem;
        line-height: 4rem;
        margin: 3rem 0; }
    @media (min-width: 1408px) {
        font-size: 3rem;
        line-height: 4rem;
        margin: 3rem 0; } }
h3 {
    margin: 1rem 0;
    @media (max-width: 1023px) {
        font-size: 1.5rem;
        line-height: 2rem; }
    @media (min-width: 1024px) and (max-width: 1407px) {
        font-size: 2rem;
        line-height: 3rem; }
    @media (min-width: 1408px) {
        font-size: 2.5rem;
        line-height: 3rem; } }
h4 {
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 1rem 0;
    font-weight: normal; }
h5 {
    font-size: 1.15rem;
    line-height: 2rem;
    margin: 1rem 0;
    font-weight: normal; }
h6 {
    font-size: 1rem;
    line-height: 2rem;
    margin: 1rem 0; }
p {
    margin: 1rem 0; }

.intro-list {
    ul {
      list-style: none; }

    ul li:before {
      content: url(../img/icons/check.svg);
      margin-right: 0.5rem; } }



.button:not(.is-small) {
    @media (max-width: 1407px) {
        padding: 0rem 2rem;
        height: 3.5rem; }
    @media (min-width: 1408px) {
        padding: 0rem 3rem;
        height: 4rem; }
    font-size: $size-5; }

a:not(.button, .navbar-item) {
    text-decoration: underline $yellow; }
.has-vertical-margins {
    margin-top: 3rem;
    margin-bottom: 3rem; }
.card {
    margin-top: 5rem;
    margin-bottom: 6rem;
    box-shadow: 0 4px 20px 0 rgba(0,0,0,0.1);
    &:not(first-child) {
        margin-left: 1rem; }
    &:not(last-child) {
        margin-right: 1rem; } }

footer.footer {
    a span {
        @media (max-width: 800px) {
                font-size: 1rem; }
        @media (min-width: 800px) {
                padding-left: 1rem; } } }

.price {
    h3 {
        margin-bottom: 0rem; }
    h4 {
        margin-top: 0rem; }
    .padded-top {
        padding-top: 2rem; }
    .padded-bottom {
        padding-bottom: 2rem; } }

.select.is-rounded {
    vertical-allign: middle; }

.button.is-small {
    height: 36px; }

.break-line {
    overflow-wrap: break-word;
    @media (min-width: 800px) {
        margin-bottom: 20px; } }

.is-fourty-five {
    flex: none;
    width: 45%; }

.title-holder {
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 754px) {
        height: 50px; } }

.title-holder-3 {
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1390px) {
        height: 96px; } }

.description {
    font-weight: normal; }

.error-code {
    font-size: 12rem;
    margin-bottom: 5rem; }

::cue {
    font-family:  'Lato', sans-serif; }


.how-it-works {
    p {
        @media (max-width: 1023px) {
            line-height: 1.5rem;
            font-weight: bold; }
        @media (min-width: 1024px) and (max-width: 1407px) {
            line-height: 1.75rem;
            font-weight: bold; }
        @media (min-width: 1408px) {
            line-height: 2rem;
            font-weight: bold; } }
    ul {
        @media (max-width: 1023px) {
            padding: 1.5rem;
            margin-bottom: 1.5rem; }
        @media (min-width: 1024px) and (max-width: 1407px) {
            padding: 1.75rem;
            margin-bottom: 1.75rem; }
        @media (min-width: 1408px) {
            padding: 2rem;
            margin-bottom: 2rem; } }
    li {
        @media (max-width: 1023px) {
            margin-bottom: 1.5rem; }
        @media (min-width: 1024px) and (max-width: 1407px) {
            margin-bottom: 1.75rem; }
        @media (min-width: 1408px) {
            margin-bottom: 2rem; } } }

.use-cases {
    ol {
        @media (max-width: 1023px) {
            margin-left:   1rem;
            margin-top:    -0.5rem;
            margin-bottom: 0rem; }
        @media (min-width: 1024px) and (max-width: 1407px) {
            margin-left:   2rem;
            margin-top:    -0.5rem;
            margin-bottom: 0rem; }
        @media (min-width: 1408px) {
            margin-left:   3rem;
            margin-top:    -0.5rem;
            margin-bottom: 0rem; } }
    li {
        @media (max-width: 1023px) {
            margin-bottom: 0.1rem; }
        @media (min-width: 1024px) and (max-width: 1407px) {
            margin-bottom: 0.15rem; }
        @media (min-width: 1408px) {
            margin-bottom: 0.25rem; } } }

.rss-feed {
    h1 {
        @media (max-width: 1023px) {
            font-size: 2rem;
            line-height: 2.5rem;
            margin-bottom: 2rem; }
        @media (min-width: 1024px) and (max-width: 1407px) {
            font-size: 2.5rem;
            line-height: 3rem;
            margin-bottom: 3rem; }
        @media (min-width: 1408px) {
            font-size: 3rem;
            line-height: 4rem;
            margin-bottom: 3rem; } }
    h2 {
        @media (max-width: 1023px) {
            font-size: 1.25rem;
            line-height: 1.75rem;
            margin: 2rem 0; }
        @media (min-width: 1024px) and (max-width: 1407px) {
            font-size: 1.5rem;
            line-height: 2rem;
            margin: 2rem 0; }
        @media (min-width: 1408px) {
            font-size: 2rem;
            line-height: 2.5rem;
            margin: 3rem 0; } }
    h3 {
        margin: 1rem 0;
        @media (max-width: 1023px) {
            font-size: 1rem;
            line-height: 1.5rem; }
        @media (min-width: 1024px) and (max-width: 1407px) {
            font-size: 1.25rem;
            line-height: 1.75rem; }
        @media (min-width: 1408px) {
            font-size: 1.5rem;
            line-height: 2rem; } } }

.newsfeed {
    iframe {
        @media (max-width: 1023px) {
            width:          600px;
            height:         600px; }
        @media (min-width: 1024px) and (max-width: 1407px) {
            width:          750px;
            height:         600px; }
        @media (min-width: 1408px) {
            width:          850px;
            height:         600px; } } }


@import "bulma-components/_steps_vertical.sass";

@import "parts/_footer.sass";
@import "parts/_fm_navbar.sass";
@import "parts/_hero_contact.sass";

@import "customstyles/_numbers.sass";
@import "customstyles/_hero_intro.sass";
@import "customstyles/_hero_install.sass";
@import "customstyles/_hero_pricing.sass";
@import "customstyles/_hero_value_list.sass";
