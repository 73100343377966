@each $kind in (disks, lines, pipes, man, talk, cans, glasses, city) {
    .has-bg-#{$kind} {
        background: $black;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @media (max-width: 768px) {
            background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),url('../img/backgrounds/#{$kind}_1x.jpg'); }
        @media (max-width: 1407px) {
            background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),url('../img/backgrounds/#{$kind}_2x.jpg'); }
        @media (min-width: 1408px) {
            background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),url('../img/backgrounds/#{$kind}_4x.jpg'); } } }


@each $kind in (proud, ok, showing, twinkle, chill, head, strong, remote, work, caring, connecting, measuring, together, fmstuff, director, consultant, consultant2) {
    .has-bg-robot-#{$kind} {
        background: $white;
        background-repeat: no-repeat;
        @media (max-width: 768px) {
            background-image: url('../img/backgrounds/robot-#{$kind}_1x.png'), url('../img/graphics/circuits_left.svg');
            background-position: bottom right, top left; }
        @media (max-width: 1407px) {
            background-image: url('../img/backgrounds/robot-#{$kind}_2x.png'), url('../img/graphics/circuits_left.svg');
            background-position: bottom right, top left; }
        @media (min-width: 1408px) {
            background-image: url('../img/backgrounds/robot-#{$kind}_4x.png'), url('../img/graphics/circuits_left.svg');
            background-position: bottom right, top left; } } }

.has-bg-about {
    background: $white;
    background-size: contain;
    margin: 5rem;
    background-repeat: no-repeat;
    background-position-x: 0;
    background-position-y: 0; }

.has-bg-about-1 {
    background: $white;
    background-size: contain;
    margin: 5rem;
    background-repeat: no-repeat;
    background-position-x: 0;
    background-position-y: 0;
    @media (max-width: 768px) {
        background-image: url('../img/photo/about_rp_1x.jpg'); }
    @media (max-width: 1407px) {
        background-image: url('../img/photo/about_rp_2x.jpg'); }
    @media (min-width: 1408px) {
        background-image: url('../img/photo/about_rp_4x.jpg'); } }

.has-sidelines {
    background-size: 100% 100%;
    background-position-y: 20%;
    background-repeat: no-repeat;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)), url('../img/graphics/sidelines.svg');
    margin-top: 6%; }

.has-sideline {
    background-size: 50% 50%;
    background-position-x: 100%;
    background-position-y: 40%;
    background-repeat: no-repeat;
    background-image: url('../img/graphics/sideline.svg'); }

.has-circuits-bot {
    background-size: 100% 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-image: url('../img/graphics/circuits_bot.svg'); }

.has-circuits-left {
    background-size: 40% 100%;
    background-position-x: 0;
    background-position-y: 0;
    background-repeat: no-repeat;
    background-image: url('../img/graphics/circuits_left.svg'); }

.has-clock-key-cut {
    margin: 5rem 0;
    background-size: 40% 100%;
    background-position-x: 0;
    background-position-y: 0;
    background-repeat: no-repeat;
    background-image: url('../img/graphics/clock_key_cut.svg'); }

.has-remote-document-cut {
    margin: 5rem 0;
    background-size: 40% 100%;
    background-position-x: 0;
    background-position-y: 0;
    background-repeat: no-repeat;
    background-image: url('../img/graphics/remote_document_cut.svg'); }

@each $kind in (sensor, wireless, configure, month, broadcast, medal) {
    .bg-#{$kind} {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url('../img/icons/#{$kind}.svg'); } }

@each $kind in (banner, square) {
    .bg-#{$kind} {
        min-height: 25rem;
        background: $white;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: 2rem; } }

@each $kind in (bar_sensor, metal_sensor, bottle_line, engine_sensor, machine_connected, engine_saw, group_tablet, demo_tablet, machines_1, machines_2, downtime_1, downtime_2, production_1, production_2, production_3, employee_1, employee_2, bottleneck_1, bottleneck_2, access_1, access_2, capacity_1, capacity_2, downtimes_1, cycles_1, smart-factory) {
    .bg-#{$kind} {
        @media (max-width: 768px) {
            background-image: url('../img/photo/#{$kind}_1x.jpg'); }
        @media (max-width: 1407px) {
            background-image: url('../img/photo/#{$kind}_2x.jpg'); }
        @media (min-width: 1408px) {
            background-image: url('../img/photo/#{$kind}_4x.jpg'); } } }

@each $kind in (smart_factory) {
    .bg-transparent-#{$kind} {
        @media (max-width: 768px) {
            background-image: url('../img/photo/#{$kind}_1x.png'); }
        @media (max-width: 1407px) {
            background-image: url('../img/photo/#{$kind}_2x.png'); }
        @media (min-width: 1408px) {
            background-image: url('../img/photo/#{$kind}_4x.png'); } } }
