.hero {
    .container.pricing {
        h1 {
            span {
                display: inline-block; }
            svg {
                display: inline-block;
                font: bold 5rem 'Oswald';
                height: 4rem;
                margin-bottom: -2px;
                text {
                    fill: none;
                    stroke: $grey;
                    stroke-width: 3px;
                    stroke-linejoin: round; } }
            svg.claim-small {
                font-size: 5rem;
                height: 3rem; }
            svg.claim-medium {
                font-size: 5rem;
                height: 5rem; }
            svg.claim-large {
                font-size: 6rem;
                height: 5rem; } }

        .is-bg {
            min-height: 6rem;
            margin-top: 5rem;
            margin-bottom: 6rem; }
        .card {
            h3, h4 {
                margin-bottom: 1rem; }
            h5 {
                margin-bottom: 2rem; }
            .discount {
                margin-bottom: 5rem; } } } }
