@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700|Oswald:300,400,700&display=swap&subset=latin-ext');

$black:        #040404;
$black-bis:    #141414;
$black-ter:    #282828;

$grey-darker:  #2a2a2a;
$grey-dark:    #4a4a4a;
$grey:         #6a6a6a;
$grey-light:   #8a8a8a;
$grey-lighter: #aaaaaa;

$white-ter:    #acacac;
$white-bis:    #cccccc;
$white:        #fcfcfc;

$orange:       #e5662b;
$yellow:       #ffa92a;
$green:        #00a896;
$turquoise:    #00798c;
$cyan:         #1f5889;
$blue:         #05668d;
$purple:       #58355e;
$red:          #e03616;

$family-sans-serif: 'Lato', sans-serif;

$weight-normal: 400;
$weight-medium: 700;
$weight-bold: 900;

$radius-small: 0px;
$radius: 1px;
$radius-large: 2px;

$size-1: 4rem;
$size-2: 2.5rem;
$size-3: 2rem;
$size-4: 1.5rem;
$size-5: 1.15rem;
$size-6: 1rem;
$size-7: 0.75rem;
