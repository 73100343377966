.hero {
    .container.contact {
        padding-top: 6rem;
        padding-bottom: 10rem;
        padding-left: 1rem;
        padding-right: 1rem;
        @media(min-width: 768px) {
            padding-left: 4rem;
            padding-right: 4rem; }
        hr {
            margin-left: 0; }
        h2 {
            margin-top: 1rem;
            font-size: 2.75rem;
            line-height: 3rem; }
        input:focus, textarea:focus {
            border: solid 2px $yellow !important; }
        input, textarea {
            border: solid 2px $white;
            background-color: transparent;
            color: $white;
            font-size: 1.1rem;
            line-height: 1.5rem;
            &::placeholder {
                color: $white-bis; } }
        input {
            height: 3.5rem; }
        textarea {
            margin-top: 1rem;
            margin-bottom: 2rem; } } }

.hero.inverted {
    .container.contact {
        input, textarea {
            border: solid 2px $black;
            background-color: transparent;
            color: $black;
            &::placeholder {
                color: $black-bis; } } } }
