.hero {
    .container.intro {
        padding-top: 4rem;
        padding-bottom: 7rem;
        font-family: $family-secondary;
        .bg-number-intro {
            background-repeat: no-repeat;
            background-size: contain;
            height: 12rem;
            width: 14.5rem;
            margin-top: -17rem;
            margin-left: 2rem; }
        .steps.is-vertical {
            border-left-style: solid;
            border-width: 4px;
            border-image-source: linear-gradient(to bottom, rgba(255, 169, 42, 0), #ffa82a 20%, #ffa52a 80%, rgba(255, 169, 42, 0));
            border-image-slice: 1;
            .steps-marker {
                width: 20px !important;
                height: 20px !important;
                margin-left: -12px;
                margin-top: 2rem; }
            .steps-content {
                margin-top: -2.75rem;
                ul {
                    margin: 2rem;
                    font-size: 1.5rem;
                    color: $black-ter;
                    text-transform: uppercase;
                    list-style: inside circle;
                    li {
                        margin-bottom: 2rem; } }
                p {
                    margin-top: 1rem;
                    font-family: $family-primary;
                    font-size: 1rem;
                    font-style: italic;
                    color: $grey; } }
            .steps-segment:not(:last-child) {
                padding-bottom: 18rem; }
            .steps-segment::after {
                  width: 0px;
                  border-style: none; } }
        ul.steps {
            margin-top: 18rem !important; }
        p {
            margin: 4rem 0; } } }
