
.footer {
    color: $grey-dark;
    font-family: $family-secondary;
    text-transform: uppercase;
    font-weight: bold;
    li {
        line-height: 2rem; }
    p {
        font-weight: normal;
        line-height: 1.75rem; }
    @media (max-width: $navbar-breakpoint) {
        padding: 1rem; }
    a:not(.button) {
        color: $grey-dark;
        text-decoration: none; }
    a:not(.button):hover {
        color: $yellow;
        text-decoration: underline $yellow; }
    .social-link {
        margin-right: 1.5rem;
        text-decoration: none; }
    .brand-img {
        max-height: 1.5rem;
        margin-bottom: 1rem; }
    .flag-icon {
        margin-left: 0.5rem; }
    .dropdown {
        .button {
            color: $grey-dark;
            border: none;
            background-color: transparent;
            font-family: $family-secondary;
            text-transform: uppercase;
            font-weight: bold; } }
    #dropdown-menu-lang {
        .dropdown-content {
            max-width: 7rem;
            padding: 0;
            background-color: $black-ter; } } }
