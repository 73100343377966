.hero {
    .container.value-list {
        padding-top: 4rem;
        ul {
            color: $yellow;
            list-style: url('../img/icons/check.svg');
            li {
                padding-left: 1rem;
                margin-top: 3rem;
                maring-bottom: 3rem;
                p {
                    color: $black;
                    font-size: 1.5rem;
                    line-height: 3rem; } } } } }
