.bg-number-1 {
    @media (max-width: 768px) {
        background-image: url('../img/numbers/01_full_1x.png'); }
    @media (max-width: 1407px) {
        background-image: url('../img/numbers/01_full_2x.png'); }
    @media (min-width: 1408px) {
        background-image: url('../img/numbers/01_full_3x.png'); } }

.bg-number-1-outline {
    @media (max-width: 768px) {
        background-image: url('../img/numbers/01_outline_1x.png'); }
    @media (max-width: 1407px) {
        background-image: url('../img/numbers/01_outline_2x.png'); }
    @media (min-width: 1408px) {
        background-image: url('../img/numbers/01_outline_3x.png'); } }

.bg-number-2 {
    @media (max-width: 768px) {
        background-image: url('../img/numbers/02_full_1x.png'); }
    @media (max-width: 1407px) {
        background-image: url('../img/numbers/02_full_2x.png'); }
    @media (min-width: 1408px) {
        background-image: url('../img/numbers/02_full_3x.png'); } }

.bg-number-2-outline {
    @media (max-width: 768px) {
        background-image: url('../img/numbers/02_outline_1x.png'); }
    @media (max-width: 1407px) {
        background-image: url('../img/numbers/02_outline_2x.png'); }
    @media (min-width: 1408px) {
        background-image: url('../img/numbers/02_outline_3x.png'); } }

.bg-number-3 {
    @media (max-width: 768px) {
        background-image: url('../img/numbers/03_full_1x.png'); }
    @media (max-width: 1407px) {
        background-image: url('../img/numbers/03_full_2x.png'); }
    @media (min-width: 1408px) {
        background-image: url('../img/numbers/03_full_3x.png'); } }

.bg-number-3-outline {
    @media (max-width: 768px) {
        background-image: url('../img/numbers/03_outline_1x.png'); }
    @media (max-width: 1407px) {
        background-image: url('../img/numbers/03_outline_2x.png'); }
    @media (min-width: 1408px) {
        background-image: url('../img/numbers/03_outline_3x.png'); } }
