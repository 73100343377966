
.fm_navbar {
    font-family: $family-secondary;
    text-transform: uppercase;
    font-weight: bold;
    .flag-icon {
        margin-left: 0.5rem; }
    .button {
        margin-top: 1.5rem;
        height: 2.5rem;
        @media (max-width: $navbar-breakpoint) {
            height: 3.5rem; } }
    .navbar-item {
        margin-left: 0.5rem; }
    .navbar-item:hover {
        text-decoration: underline $yellow; }
    .navbar-dropdown a.navbar-item {
        margin: 0.5rem;
        font-size: $size-6; }
    .navbar-end {
        .navbar-link:hover {
            &:not(.is-arrowless)::after {
                border-color: $yellow; } }
        .navbar-link:not(.is-arrowless) {
            padding-right: 1.5rem; }
        .navbar-link:not(.is-arrowless)::after {
            border-color: $black;
            right: 0.15rem; }
        @media (max-width: $navbar-breakpoint) {
            background-color: $white; } }
    .dropdown {
        .button {
            border: none;
            background-color: transparent;
            font-family: $family-secondary;
            text-transform: uppercase;
            font-weight: bold; } }
    #dropdown-menu-lang {
        .dropdown-content {
            max-width: 7rem;
            padding: 0; } } }

.fm_navbar.inverted {
    .navbar-item, .navbar-link {
        &:not(:hover) {
            &:not(.is-active) {
                color: $white; } }
        a {
            &:not(.is-active) {
                color: $white !important; } }
        a:hover {
            color: $yellow !important; } }
    a.navbar-item.button:hover {
        color: $white !important; }
    .navbar-item:hover .navbar-link {
        color: $yellow !important; }
    .navbar-burger {
        color: $white !important; }
    .navbar-end {
        .navbar-link:not(:hover) {
            &:not(.is-arrowless)::after {
                border-color: $white; } }
        @media (max-width: $navbar-breakpoint) {
            background-color: $black; } }
    .navbar-dropdown {
        background-color: $black;
        .navbar-item, .navbar-link {
            background-color: $black; } }
    .dropdown {
        .button {
            border: none;
            color: $white;
            background-color: transparent;
            font-family: $family-secondary;
            text-transform: uppercase;
            font-weight: bold; } }
    #dropdown-menu-lang {
        .dropdown-content {
            max-width: 7rem;
            padding: 0;
            background-color: $black !important; } } }

.fm_navbar.scrolled, .fm_navbar.expanded {
    background-color: #fff !important;
    transition: background-color $speed linear;
    border-bottom: 1px solid $grey-lighter; }

.fm_navbar.inverted.scrolled, .fm_navbar.inverted.expanded {
    background-color: #000 !important;
    transition: background-color $speed linear;
    border-bottom: 1px solid $grey-darker; }

.navbar-desktop {
    padding: 0;
    .button {
        padding: 0.75rem 1rem;
        vertical-align: center; } }

.navbar-mobile {
    padding: 0;
    padding-bottom: 10px;
    padding-right: 10px;
    .button {
        padding: 0.75rem 1rem;
        vertical-align: center;
        height: 3.5rem; } }
