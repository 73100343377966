.hero {
    .container.install {
        padding-left: 2rem;
        padding-right: 2rem;
        .bg-number-install {
            background-repeat: no-repeat;
            background-size: contain;
            height: 15rem;
            width: 17.5rem;
            margin-left: -6rem; }
        h2 {
            margin-top: 1rem;
            font-size: 2.75rem;
            line-height: 3rem; }
        .column {
            margin-top: 5rem;
            margin-bottom: 5rem; }
        p {
            font-size: 1.5rem; }
        .bg-install-step {
            margin-top: -6rem;
            margin-left: 6rem;
            height: 8rem;
            width: 8rem; } } }
