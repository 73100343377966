$primary: $yellow;

$info: $blue;
$success: $green;
$warning: $orange;
$danger: $red;

$light: $white-ter;
$dark: $black-ter;

$background: $white;

$border: $white;
$border-hover: $yellow;

$text: $black;
$text-light: $black;
$text-strong: $black;

$code: $black;

$link: $yellow;
$link-invert: $orange;
$link-visited: $orange;

$link-hover: lighten($yellow, 20%);
$link-hover-border: lighten($yellow, 20%);

$link-focus: lighten($yellow, 20%);
$link-focus-border: lighten($yellow, 20%);

$link-active: $yellow;
$link-active-border: $yellow;

$family-secondary: 'Oswald', sans-serif;



