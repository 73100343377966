.steps.is-vertical {
    border-left-style: solid;
    border-width: 4px;
    border-image-source: linear-gradient(to bottom, rgba(255, 169, 42, 0), #ffa82a 5%, #ffa52a 95%, rgba(255, 169, 42, 0));
    border-image-slice: 1;
    .steps-marker {
        width: 20px !important;
        height: 20px !important;
        margin-left: -12px;
        margin-top: 1rem; }
    .year-number {
        padding-left: 2rem;
        padding-bottom: 2rem;
        svg {
            font: bold 6rem 'Oswald';
            text {
                fill: none;
                stroke: $grey;
                stroke-width: 2px;
                stroke-linejoin: round; } } }
    .steps-content {
        h2 {
            margin-bottom: 2rem;
            margin-top: 0;
            color: $yellow;
            font-weight: bold; }
        ul {
            margin: 2rem;
            font-size: 1.5rem;
            color: $black-ter;
            text-transform: uppercase;
            list-style: inside circle;
            li {
                margin-bottom: 2rem; } }
        p {
            font-size: 1.5rem;
            line-height: 2.5rem;
            color: $grey;
            margin-bottom: 3rem; } }
    .steps-segment::after {
          border-style: none;
          background-color: transparent; } }
