// https://bulma.io/documentation/customize/variables/

@import "bulma-components/_navbar.sass";
@import "bulma-components/_button.sass";
@import "bulma-components/_hr.sass";

$footer-background-color: $black-bis;
$footer-padding: 3rem 0rem;

$body-font-size: $size-5;
$body-line-height: 2.5rem;


$table-row-hover-background-color:  #111111;
$box-background-color: hsl(0%, 0%, 100%);
